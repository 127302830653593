<template>
  <div>
      <Post/>
  </div>
</template>

<script>
import Post from './components/Post'
export default {
  components: {
    Post,
  },
  data() {
    return {
    }
  },
}
</script>
